import { getCDNImage } from 'utils/utils';

const ProductImage = ({
  image,
  alt,
  onClick,
  onLoad,
  className,
  lowres,
  lazy,
  draggable,
  id = '',
}) => {
  const imageFormats = ['avif', 'webp', 'png'];

  // Ursprüngliche Bild-URL analysieren
  const originalMatch = image.match(/\.([a-zA-Z0-9]+)$/);
  const originalFormat = originalMatch ? originalMatch[1] : null;

  // Basis-Pfad ohne Endung
  const galleryImageBase = getCDNImage(image).replace(/\.[a-zA-Z0-9]+$/, '');

  // Bekannte Formate: WebP, PNG, AVIF → Reihenfolge anpassen
  let filteredFormats;
  if (originalFormat === 'avif') {
    filteredFormats = imageFormats; // Alle Formate nutzen
  } else if (originalFormat === 'webp') {
    filteredFormats = ['webp', 'png']; // Kein AVIF
  } else if (originalFormat === 'png') {
    filteredFormats = ['png']; // Kein AVIF, kein WebP
  } else {
    filteredFormats = [originalFormat]; // Unbekanntes Format → Nur Original nutzen
  }

  return (
    <picture>
      {filteredFormats.map((format) => (
        <source
          key={format}
          srcSet={`${galleryImageBase}${lowres ? '_lowres' : ''}.${format}`}
          type={`image/${format}`}
        />
      ))}
      <img
        src={`${galleryImageBase}${lowres ? '_lowres' : ''}.${
          filteredFormats[0]
        }`}
        alt={alt}
        className={className}
        onClick={onClick}
        onLoad={onLoad}
        draggable={draggable ?? true}
        loading={lazy ? 'lazy' : 'eager'}
        id={id}
      />
    </picture>
  );
};

export default ProductImage;
