import React, { useContext, useEffect, useReducer, useState } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, ListGroup, Row, Stack } from 'react-bootstrap';
import { Store } from 'Store';
import axios from 'axios';
import {
  checkCartHandler,
  getCDNImage,
  getProductPrices,
  getSizeText,
  getTotalOrderPrice,
  getTotalProductsPrice,
  round2,
  sendEvent,
} from 'utils/utils';
import RemoveProductModal from 'components/modals/RemoveProduct';
import LoadingBox from 'components/LoadingBox';
import CartSummary from 'components/Cart/CartSummary';
import ProductImage from 'components/ProductImage';

const reducer = (state, action) => {
  switch (action.type) {
    case 'REQUEST':
      return { ...state, loading: true };
    case 'SUCCESS':
      return { ...state, loading: false };
    default:
      return state;
  }
};

export function CartSidebar(props) {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    cart: { cartItems },
    reserved,
    id,
    orderInfo,
  } = state;

  const [{ loading }, dispatch] = useReducer(reducer, {
    loading: false,
  });

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [showRemoveProductModal, setShowRemoveProductModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});

  const { isMenuActive, onOverLayClick } = props;

  const [orderPrices, setOrderPrices] = useState(
    getTotalOrderPrice(
      cartItems,
      0, //deliveryCosts[orderInfo?.deliveryMethod ?? ''],
      orderInfo?.coupon ?? null
    )
  );

  useEffect(() => {
    setOrderPrices(
      getTotalOrderPrice(
        cartItems,
        0, //deliveryCosts[orderInfo?.deliveryMethod ?? ''],
        orderInfo?.coupon ?? null
      )
    );
  }, [cartItems, orderInfo]);

  const sideMenuClasses = classnames('side-menu side-menu-right', {
    'side-menu--active': isMenuActive,
    'side-menu-right--active': isMenuActive,
  });
  const sideMenuContentClasses = classnames(
    'side-menu__content side-menu-right__content',
    {
      'side-menu__content--active': isMenuActive,
      'side-menu-right__content--active': isMenuActive,
    }
  );

  const checkoutHandler = async () => {
    props.onOverLayClick();
    dispatch({ type: 'REQUEST' });
    if (
      await checkCartHandler({ dispatch, ctxDispatch, cartItems, reserved, id })
    ) {
      dispatch({ type: 'SUCCESS' });
      sendEvent('begin_checkout', {
        currency: 'EUR',
        value: getTotalProductsPrice(cartItems).productsTotalWithDiscount,
        items: cartItems.map((item) => {
          return {
            item_id: item.itemNumber,
            discount: round2(
              getProductPrices(item).price - getProductPrices(item).withDiscount
            ),
            item_brand: item.brand,
            item_category: item.category,
            item_size: getSizeText(item),
            price: getProductPrices(item).withDiscount,
            quantity: 1,
          };
        }),
      });
      navigate('/bestellung/benutzerdaten', {
        state: { prevPage: pathname },
      });
    }
    dispatch({ type: 'SUCCESS' });
  };

  const removeItemHandler = async (item) => {
    // if (window.confirm('Artikel wirklich löschen?')) {
    let removedProduct = document.getElementById(item._id);
    removedProduct.style.opacity = '0';

    setTimeout(() => {
      ctxDispatch({
        type: 'CART_REMOVE_ITEM',
        payload: item,
      });
    }, 2000);

    sendEvent('remove_from_cart', {
      currency: 'EUR',
      value: getProductPrices(item).withDiscount,
      items: [
        {
          item_id: item.itemNumber,
          discount: round2(
            getProductPrices(item).price - getProductPrices(item).withDiscount
          ),
          item_brand: item.brand,
          item_category: item.category,
          item_size: getSizeText(item),
          price: getProductPrices(item).withDiscount,
          quantity: 1,
        },
      ],
    });

    await axios.get('/api/users/removeproduct', {
      headers: {
        userid: id,
        cart: cartItems.map((c) => c._id),
        page: `Artikel`,
        productid: item._id,
      },
    });
  };

  useEffect(() => {
    const checkCart = async () => {
      await checkCartHandler({
        dispatch,
        ctxDispatch,
        cartItems,
        reserved,
        id,
      });
    };
    if (cartItems.length > 0) checkCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems]);

  return (
    <aside className={sideMenuClasses}>
      <div
        className={'side-menu__overlay side-menu-right__overlay'}
        onClick={props.onOverLayClick}
      />
      <div className={sideMenuContentClasses}>
        <RemoveProductModal
          show={showRemoveProductModal}
          onHide={() => setShowRemoveProductModal(false)}
          removeItemHandler={removeItemHandler}
          selectedProduct={selectedProduct}
        />
        <Row className="mb-3">
          <Col>
            <div
              className="closeSideMenuRight d-flex justify-content-start"
              onClick={props.onOverLayClick}
            >
              <FontAwesomeIcon
                icon="rectangle-xmark"
                style={{ color: '#333' }}
                size="xl"
              />
            </div>
          </Col>
          <Col className="text-end">
            <h4>Warenkorb</h4>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="g-0">
            <ListGroup variant="flush" className="cartList">
              {cartItems.length === 0 ? (
                <ListGroup.Item key="empty_card" className="my-2">
                  Huch, dein Warenkorb ist noch leer. Schnell zugreifen, bevor
                  jemand schneller ist!
                </ListGroup.Item>
              ) : (
                <React.Fragment>
                  <ListGroup.Item key="cart_header" className="cart_header">
                    <Row className="d-flex align-items-end h-100">
                      <Col className="text-start">Deine Artikel</Col>
                      <Col className="text-end">Preis</Col>
                    </Row>
                  </ListGroup.Item>
                  {cartItems.map((item) => (
                    <ListGroup.Item
                      key={item._id}
                      className="cartList__item mt-2"
                      id={item._id}
                    >
                      <Row>
                        <Col xs={4}>
                          <ProductImage
                            image={item.image}
                            alt={item.name}
                            onClick={() => {
                              onOverLayClick();
                              navigate(`/product/${item._id}`);
                            }}
                            className="img-fluid rounded img-thumbnail"
                            lowres={true}
                          />
                        </Col>
                        <Col
                          xs={5}
                          onClick={() => {
                            onOverLayClick();
                            navigate(`/product/${item._id}`);
                          }}
                        >
                          <Stack gap={0}>
                            <div
                              className="fw-bold"
                              style={{
                                overflowWrap: 'break-word',
                                wordBreak: 'break-word',
                              }}
                            >
                              {item.category}
                            </div>
                            <div>{item.brand}</div>
                            <div style={{ minHeight: '1.5rem' }}>
                              {/* {item.description} */}
                            </div>
                            <div className="text_cart__size">
                              {item.sizeHat && item.sizeHat.length > 0 ? (
                                <>
                                  {item.sizeHat.length >= 2 ? (
                                    <div>
                                      Hutgr.{` `}
                                      {Math.min(...item.sizeHat)} -{' '}
                                      {Math.max(...item.sizeHat)}
                                    </div>
                                  ) : (
                                    <div>
                                      Hutgr.{` `}
                                      {item.sizeHat[0]}
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>Gr. {item.size}</>
                              )}
                            </div>
                          </Stack>
                        </Col>
                        <Col xs={3}>
                          {!pathname.startsWith('/bestellung') && (
                            <Row className="h-50 justify-content-end">
                              <Col className="text-end">
                                <FontAwesomeIcon
                                  icon="xmark"
                                  onClick={() => {
                                    setSelectedProduct(item);
                                    setShowRemoveProductModal(true);
                                  }} //removeItemHandler(item)}
                                  size="lg"
                                />
                              </Col>
                            </Row>
                          )}
                          {item.sale ? (
                            <>
                              <Row
                                className={`${
                                  pathname.startsWith('/bestellung')
                                    ? 'h-75'
                                    : 'h-25'
                                } justify-content-end`}
                              >
                                <Col
                                  className="align-self-end h6 text-end"
                                  style={{
                                    textDecoration: 'line-through',
                                    color: '#777777',
                                  }}
                                >
                                  {item &&
                                    item.price.toLocaleString('de-DE', {
                                      style: 'currency',
                                      currency: 'EUR',
                                    })}
                                </Col>
                              </Row>
                              <Row className="h-25 justify-content-end">
                                <Col
                                  className="align-self-end h6 text-end"
                                  style={{ color: 'red' }}
                                >
                                  {getProductPrices(
                                    item
                                  ).withDiscount.toLocaleString('de-DE', {
                                    style: 'currency',
                                    currency: 'EUR',
                                  })}
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <Row
                              className={`${
                                pathname.startsWith('/bestellung')
                                  ? item.sale
                                    ? 'h-25'
                                    : 'h-50'
                                  : item.sale
                                  ? 'h-25'
                                  : 'h-50'
                              } justify-content-end`}
                            >
                              <Col className="align-self-end h6 text-end">
                                {item &&
                                  item.price.toLocaleString('de-DE', {
                                    style: 'currency',
                                    currency: 'EUR',
                                  })}
                              </Col>
                            </Row>
                          )}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}

                  {/* <ListGroup.Item key="cart_summary" className="cart_footer">
                    <Row className="mt-3 text-end h6">
                      <Col xs={9}>
                        <div>Summe Artikel:</div>
                      </Col>
                      <Col
                        xs={3}
                        style={{
                          textDecoration: 'none',
                          color: 'black',
                        }}
                      >
                        {orderPrices.productsTotalWithoutDiscount.toLocaleString(
                          'de-DE',
                          {
                            style: 'currency',
                            currency: 'EUR',
                          }
                        )}
                      </Col>
                    </Row>
                    {orderPrices.hasSale && (
                      <>
                        <Row className="mt-3 text-end h6">
                          <Col xs={9}>
                            <div>Sale:</div>
                          </Col>
                          <Col
                            xs={3}
                            style={{
                              color: 'red',
                            }}
                          >
                            {round2(
                              orderPrices.productsTotalWithDiscount -
                                orderPrices.productsTotalWithoutDiscount
                            ).toLocaleString('de-DE', {
                              style: 'currency',
                              currency: 'EUR',
                            })}
                          </Col>
                        </Row>
                        <Row
                          className="pt-3 mb-3 text-end h6"
                          style={{
                            borderTop:
                              '2px solid var(--bs-list-group-border-color)',
                          }}
                        >
                          <Col xs={9}>
                            <div>Zwischensumme:</div>
                          </Col>
                          <Col xs={3}>
                            {orderPrices.productsTotalWithDiscount.toLocaleString(
                              'de-DE',
                              {
                                style: 'currency',
                                currency: 'EUR',
                              }
                            )}
                          </Col>
                        </Row>
                      </>
                    )}
                    {orderInfo?.coupon && orderPrices.couponDiscount < 0 && (
                      <>
                        <Row className="mt-3 text-end h6 mb-0">
                          <Col xs={9}>
                            <div>Rabattcode:</div>
                          </Col>
                          <Col
                            xs={3}
                            style={{
                              color: 'red',
                            }}
                          >
                            {orderPrices.couponDiscount.toLocaleString(
                              'de-DE',
                              {
                                style: 'currency',
                                currency: 'EUR',
                              }
                            )}
                          </Col>
                        </Row>
                        <Row
                          className="text-end"
                          style={{
                            fontSize: '0.85rem',
                          }}
                        >
                          <Col xs={9}>({orderInfo?.coupon?.description})</Col>
                          <Col
                            xs={3}
                            className="text-end"
                            style={{
                              color: 'gray',
                              cursor: 'pointer',
                            }}
                            onClick={() => removeCouponHandler({ ctxDispatch })}
                          >
                            Entfernen
                          </Col>
                        </Row>
                      </>
                    )}
                  </ListGroup.Item>
                  {pathname.startsWith('/bestellung') ||
                  (orderInfo?.coupon &&
                    orderPrices?.couponDiscount &&
                    orderPrices?.valueToPay === 0) ? (
                    <></>
                  ) : (
                    <ListGroup.Item className="cart_footer">
                      <CodeInsert />
                    </ListGroup.Item>
                  )}
                  <ListGroup.Item key="cart_total" className="cart_footer">
                    <Row className="mt-3 text-end h5">
                      <Col xs={9}>
                        <div>Gesamt:</div>
                      </Col>
                      <Col xs={3}>
                        {orderPrices.totalPrice.toLocaleString('de-DE', {
                          style: 'currency',
                          currency: 'EUR',
                        })}
                      </Col>
                    </Row>
                  </ListGroup.Item> */}
                  <CartSummary
                    hideCodeInsert={pathname.startsWith('/bestellung')}
                  />
                </React.Fragment>
              )}
            </ListGroup>
          </Col>
          {loading && (
            <Col xs={12}>
              <LoadingBox />
            </Col>
          )}
          <Col>
            {cartItems.length > 0 &&
            !window.location.pathname.startsWith('/bestellung') ? (
              <>
                <Row className="mx-3 mt-5 mb-4 justify-content-center">
                  <div
                    onClick={() => checkoutHandler()}
                    disabled={cartItems.length === 0}
                    className="btn_action"
                  >
                    {`Zur Kasse >`}
                  </div>
                </Row>
                <Row
                  className="mx-3 mb-5 justify-content-center"
                  style={{ paddingBottom: '100px' }}
                >
                  <div
                    onClick={() => {
                      navigate('/warenkorb', {
                        state: { prevPage: pathname },
                      });
                      onOverLayClick();
                    }}
                    disabled={cartItems.length === 0}
                    className="btn btn_light"
                  >
                    {`Zum Warenkorb`}
                  </div>
                </Row>
              </>
            ) : (
              <Row className="mx-3 mb-5"></Row>
            )}
          </Col>
        </Row>
      </div>
    </aside>
  );
}

export default CartSidebar;
